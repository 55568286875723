<template style="background: white">
  <v-card light>
    <v-card-title>
      <h3 style="color: #d52787">Totes les autoavaluacions</h3>
      <v-icon class="ml-2" color="#d52787 !important" small v-on:click="getData">mdi-reload</v-icon>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          color="#d52787"
          hide-details
          label="Buscar"
          single-line/>
    </v-card-title>
    <v-alert
        v-if="errorInCharge"
        dense
        outlined
        type="error">
      Hi ha hagut un error en obtenir les dades, intenta-ho de nou més tard
    </v-alert>
    <v-data-table
        :footer-props="{
          'items-per-page-text':'Autoavaluacions per pagina'
        }"
        :headers="headers"
        :items="clients"
        :loading="!loaded"
        :search="search"
        loading-text="Carregant... Si us plau, espera"
        no-data-text="Encara no hi ha clients">
      <template v-slot:item.phone="{item}">
       <a :href="'tel:'+item.phone" target="_blank" style="text-decoration: none; color:black; font-weight: 500">{{ item.phone }} </a>
      </template>
      <template v-slot:item.web="{item}">
        <a :href="item.web" target="_blank" style="text-decoration: none; color:black; font-weight: 500">{{ item.web }} </a>
      </template>
      <template v-slot:item.actions="{item}">
        <router-link :to="'/client/'+item.idclient">
          <v-icon
              class="mr-4 listIcon" small
              v-bind="attrs"
              v-on="on">
            mdi-eye
          </v-icon>
        </router-link>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Home',
  data() {
    return {
      errorInCharge:false,
      search: '',
      clients: [],
      loaded:false,
      headers: [
        {
          text: 'NIF',
          align: 'start',
          value: 'nif',
        },
        {text: 'Nom', value: 'name'},
        {text: 'Sector', value: 'sector'},
        {text: 'Subsector', value: 'subsector'},
        {text: 'Telèfon', value: 'phone'},
        {text: 'Web', value: 'web'},
        {text: 'Puntuació', value: 'score'},
        {text: 'Data', value: 'date'},
        {text: 'Accions', value:'actions'}
      ]
    }
  },
  mounted() {
    this.getData();
  },
  methods: {

    getData() {
      this.errorInCharge = false
      let url = this.env_url + "/getclients.php"
      this.axios.get(url).then(response => {
        this.clients = response.data.clients;
        this.loaded = true;
      }).catch(()=>{
        this.errorInCharge = true
      });
    },

  }
}
</script>
