var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"light":""}},[_c('v-card-title',[_c('h3',{staticStyle:{"color":"#d52787"}},[_vm._v("Totes les autoavaluacions")]),_c('v-icon',{staticClass:"ml-2",attrs:{"color":"#d52787 !important","small":""},on:{"click":_vm.getData}},[_vm._v("mdi-reload")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","color":"#d52787","hide-details":"","label":"Buscar","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.errorInCharge)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" Hi ha hagut un error en obtenir les dades, intenta-ho de nou més tard ")]):_vm._e(),_c('v-data-table',{attrs:{"footer-props":{
        'items-per-page-text':'Autoavaluacions per pagina'
      },"headers":_vm.headers,"items":_vm.clients,"loading":!_vm.loaded,"search":_vm.search,"loading-text":"Carregant... Si us plau, espera","no-data-text":"Encara no hi ha clients"},scopedSlots:_vm._u([{key:"item.phone",fn:function(ref){
      var item = ref.item;
return [_c('a',{staticStyle:{"text-decoration":"none","color":"black","font-weight":"500"},attrs:{"href":'tel:'+item.phone,"target":"_blank"}},[_vm._v(_vm._s(item.phone)+" ")])]}},{key:"item.web",fn:function(ref){
      var item = ref.item;
return [_c('a',{staticStyle:{"text-decoration":"none","color":"black","font-weight":"500"},attrs:{"href":item.web,"target":"_blank"}},[_vm._v(_vm._s(item.web)+" ")])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{attrs:{"to":'/client/'+item.idclient}},[_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4 listIcon",attrs:{"small":""}},'v-icon',_vm.attrs,false),_vm.on),[_vm._v(" mdi-eye ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }